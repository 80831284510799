exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-coaches-tsx": () => import("./../../../src/pages/coaches.tsx" /* webpackChunkName: "component---src-pages-coaches-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-membership-tsx": () => import("./../../../src/pages/membership.tsx" /* webpackChunkName: "component---src-pages-membership-tsx" */),
  "component---src-pages-players-tsx": () => import("./../../../src/pages/players.tsx" /* webpackChunkName: "component---src-pages-players-tsx" */),
  "component---src-pages-rankings-tsx": () => import("./../../../src/pages/rankings.tsx" /* webpackChunkName: "component---src-pages-rankings-tsx" */)
}

